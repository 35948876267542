"use strict";
var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/queries/index.ts
var queries_exports = {};
__export(queries_exports, {
  forecastHealthCheck: () => forecastHealthCheck_default,
  getAvailableSlots: () => getAvailableSlots_default,
  getBookingByID: () => getBookingByID_default,
  getBookings: () => getBookings_default,
  getConsumer: () => getConsumer_default,
  getConsumerByID: () => getConsumerByID_default,
  getEntitlementsForProduct: () => getEntitlementsForProduct_default,
  getFlightDetails: () => getFlightDetails_default,
  getOutlet: () => getOutlet_default,
  getOutlets: () => getOutlets_default,
  getOutletsCount: () => getOutletsCount_default,
  getPartnerBrands: () => getPartnerBrands_default,
  getPartnerBrandsCount: () => getPartnerBrandsCount_default,
  getPartnerByID: () => getPartnerByID_default,
  getSearchExperiences: () => getSearchExperiences_default
});
module.exports = __toCommonJS(queries_exports);

// src/apollo.ts
var import_client = require("@apollo/client");
var import_link_error = require("@apollo/link-error");
var import_deepmerge = __toESM(require("deepmerge"));
var import_isEqual = __toESM(require("lodash/isEqual"));
var import_react = require("react");
var import_session = require("supertokens-auth-react/recipe/session");
var import_client2 = require("@apollo/client");
var graphqlUrl = process.env.NEXT_PUBLIC_PRODUCTION_API_URL;
var checkProtected = () => {
  if (typeof window !== "undefined") {
    const { pathname } = window.location;
    return !pathname.startsWith("/auth") && !pathname.startsWith("/signup");
  }
};
var redirectLogin = () => {
  if (typeof window !== "undefined") {
    window.location.href = `/auth/login/?redirecturl=${window.location.pathname + window.location.search}`;
  }
};
var withTokenRefresh = new import_client.ApolloLink((operation, forward) => {
  if (checkProtected()) {
    return (0, import_client.fromPromise)(
      (0, import_session.attemptRefreshingSession)().then((success) => {
        if (!success) {
          redirectLogin();
        }
        return (0, import_client.toPromise)(forward(operation));
      })
    );
  }
  return forward(operation);
});
var httpLink = new import_client.HttpLink({
  uri: graphqlUrl,
  credentials: "include"
});
var errorLink = (0, import_link_error.onError)(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(
      ({ message, locations, path }) => console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  }
  if (networkError) {
    console.log(
      `[Network error]: ${networkError}. Backend is unreachable. Is it running?`,
      `[Graphql URL]: ${graphqlUrl}`
    );
  }
});

// src/queries/getBookingByID.ts
var getBookingByID = import_client2.gql`
  query GetBookingById($getBookingById: ID!) {
    getBookingByID(id: $getBookingById) {
      actingAccount
      bookedFrom
      bookedTo
      lastArrival
      metadata
      reference
      price
      price_currency
      guestAdultCount
      guestChildrenCount
      guestInfantCount
      status
      id
      consumer {
        emailAddress
        fullName
        id
      }
      experience {
        loungeName
        openingHours
        id
        images {
          altText
          contentType
          height
          id
          url
          width
        }
        location {
          airportName
          terminal
        }
        pricing {
          currency
          reservationOnlyFee
          reservationCost
        }
      }
      refundStatus
      usedEntitlementsCount
    }
  }
`;
var getBookingByID_default = getBookingByID;

// src/queries/getBookings.ts
var getBookings = import_client2.gql`
  query GetBookings($status: BookingStatus, $experienceId: ID!) {
    getBookings(status: $status, experienceID: $experienceId) {
      bookedFrom
      bookedTo
      createdAt
      type
      metadata
      id
      reference
      guestAdultCount
      guestChildrenCount
      guestInfantCount
      status
      createdAt
      updatedAt
      consumer {
        emailAddress
        firstName
        fullName
        id
      }
      experience {
        id
        loungeName
      }
    }
  }
`;
var getBookings_default = getBookings;

// src/queries/getSearchExperiences.ts
var import_client3 = require("@apollo/client");
var getSearchExperiences = import_client3.gql`
  query SearchExperiences($query: String, $searchFilter: SearchFilterInput) {
    searchExperiences(query: $query, searchFilter: $searchFilter) {
      id
      loungeName
      loungeCode
      location {
        airportName
        airportCode
        city
        country
        terminal
        timezone
      }
      partnerIdProd
      partnerIdTest
      partnerIntegrationId
      pricing {
        pricingType
        currency
        reservationCost
        lifestyleXReservationCharge
        walkInCostCurrentPPRate
        lifestyleXWalkInCharge
        lifestyleXReservationCharge
        vat
        reservationOnlyFeeCost
        reservationOnlyFee
      }
      facilities
      openingHours
      conditions
      directions
      images {
        altText
        url
        height
        width
        id
      }
    }
  }
`;
var getSearchExperiences_default = getSearchExperiences;

// src/queries/getConsumer.ts
var getConsumer = import_client2.gql`
  query GetConsumer {
    getConsumer {
      id
      crmId
      fullName
      firstName
      lastName
      emailAddress
      createdAt
      updatedAt
    }
  }
`;
var getConsumer_default = getConsumer;

// src/queries/getAvailableSlots.ts
var getAvailableSlots = import_client2.gql`
  query GetAvailableSlots($data: AvailabilityInput!) {
    getAvailableSlots(data: $data) {
      slots {
        startDate
        endDate
        maxDuration
        slotConfigurationId
      }
    }
  }
`;
var getAvailableSlots_default = getAvailableSlots;

// src/queries/getFlightDetails.ts
var getFlightDetails = import_client2.gql`
  query GetFlightDetails($flightDetails: FlightDetailsInput!) {
    getFlightDetails(flightDetails: $flightDetails) {
      arrival {
        airport
        terminal
        dateTime {
          local
          utc
        }
      }
      departure {
        airport
        terminal
        dateTime {
          local
          utc
        }
      }
    }
  }
`;
var getFlightDetails_default = getFlightDetails;

// src/queries/getPartnerByID.ts
var getPartnerByID = import_client2.gql`
  query GetPartnerByID($getPartnerById: ID!) {
    getPartnerByID(id: $getPartnerById) {
      id
      lastName
      updatedAt
      firstName
      fullName
      createdAt
      emailAddress
    }
  }
`;
var getPartnerByID_default = getPartnerByID;

// src/queries/getConsumerByID.ts
var getConsumerByID = import_client2.gql`
  query GetConsumerByID($getConsumerById: ID!) {
    getConsumerByID(id: $getConsumerById) {
      linkedAccounts {
        membershipID
        membershipType
        provider
        updatedAt
        id
        createdAt
        analytics
        externalID
      }
      firstName
      lastName
      dateOfBirth
      createdAt
      emailAddress
      id
      updatedAt
      locale
    }
  }
`;
var getConsumerByID_default = getConsumerByID;

// src/queries/getPartnerBrands.ts
var getPartnerBrands = import_client2.gql`
  query GetPartnerBrands(
    $page: Int
    $pageSize: Int
    $filters: PartnerBrandFilters
  ) {
    getPartnerBrands(page: $page, pageSize: $pageSize, filters: $filters) {
      items {
        id
        name
        outlets {
          id
        }
        status
      }
      pageInfo {
        currentPage
        hasNextPage
        hasPreviousPage
        totalPages
      }
      totalItemCount
    }
  }
`;
var getPartnerBrands_default = getPartnerBrands;

// src/queries/getPartnerBrandsCount.ts
var getPartnerBrandsCount = import_client2.gql`
  query GetPartnerBrandsCount($filters: PartnerBrandFilters) {
    getPartnerBrands(filters: $filters) {
      totalItemCount
    }
  }
`;
var getPartnerBrandsCount_default = getPartnerBrandsCount;

// src/queries/getOutlets.ts
var getOutlets = import_client2.gql`
  query GetOutlets(
    $page: Int
    $pageSize: Int
    $filters: OutletAndProductFilters
  ) {
    getOutlets(page: $page, pageSize: $pageSize, filters: $filters) {
      items {
        draft {
          media {
            mainImage {
              asset {
                url
                description
                title
              }
            }
            images {
              asset {
                url
              }
            }
          }
        }
        id
        name
        legacyCode
        status
        location {
          name
          terminal
          type
        }
        partnerBrand {
          id
          name
        }
        category
        media {
          mainImage {
            asset {
              url
              description
              title
            }
          }
          images {
            asset {
              contentType
              url
              description
              title
            }
          }
        }
      }
      pageInfo {
        currentPage
        hasNextPage
        hasPreviousPage
        totalPages
      }
      totalItemCount
    }
  }
`;
var getOutlets_default = getOutlets;

// src/queries/getOutletsCount.ts
var getOutletsCount = import_client2.gql`
  query GetOutletsCount($filters: OutletAndProductFilters) {
    getOutlets(filters: $filters) {
      totalItemCount
    }
  }
`;
var getOutletsCount_default = getOutletsCount;

// src/queries/forecastHealthCheck.ts
var forecast = import_client2.gql`
  query Forecast($loungeBusynessInput: LoungeBusynessInput) {
    forecast(loungeBusynessInput: $loungeBusynessInput) {
      level
    }
  }
`;
var forecastHealthCheck_default = forecast;

// src/queries/getOutlet.ts
var getOutlet = import_client2.gql`
  query GetOutlet(
    $getOutletId: ID!
    $outletIdType: outletIDType!
    $additionalFilters: ProductFilters
  ) {
    getOutlet(
      outletIDType: $outletIdType
      additionalFilters: $additionalFilters
      id: $getOutletId
    ) {
      id
      name
      maxGuests
      maxStayHours
      draft {
        id
        name
        accessTimes {
          accessHours {
            monday {
              endTime
              startTime
            }
            tuesday {
              endTime
              startTime
            }
            wednesday {
              endTime
              startTime
            }
            thursday {
              endTime
              startTime
            }
            friday {
              endTime
              startTime
            }
            saturday {
              endTime
              startTime
            }
            sunday {
              endTime
              startTime
            }
          }
          description
          exceptions {
            accessHours {
              startTime
              endTime
            }
            date
            startDate
            endDate
            exceptionType
            isOpen
            name
            priority
            isRecurring
          }
          peakHours {
            startTime
            endTime
          }
        }
        conditions {
          allowedSmokingPolicy
          childrenAdmittedFreeNumber {
            maximum
            per
          }
          childrenAdmittedFreeAge {
            from
            to
          }
          childrenAllowed
          childrenMustBeAccompaniedUnderAge
          description
          dressCodeOptions {
            noAboveKneeSkirtsOrDresses
            noBaseballCaps
            noSandals
            noShorts
            noSleevelessTops
            noSportswear
            noTShirts
            smartCasual
          }
          hasDressCode
          minimumChildAge
          smokingAllowed
        }
        display {
          conditions
          locationDetails
        }
        facilities {
          id
          name
          isAvailable
        }
        location {
          pointOfInterestID
          city
          code
          country
          isoCountryCode
          landside
          latitude
          longitude
          terminal
          timezone
          type
        }
        locationDetails {
          outletZone
          passportControl
          gateNumber
          gateLocationDirection
          concourse {
            letterOrNumber
            name
            directions {
              takeStairs
              takeLift
              takeEscalator
              takeBus
              takeShuttle
              takeTrain
            }
          }
          floor {
            letterOrNumber
            name
            directions {
              takeStairs
              takeLift
              takeEscalator
              takeBus
              takeShuttle
              takeTrain
            }
          }
          level {
            letterOrNumber
            name
            directions {
              takeStairs
              takeLift
              takeEscalator
              takeBus
              takeShuttle
              takeTrain
            }
          }
          pier {
            letterOrNumber
            name
          }
          satellite {
            letterOrNumber
            name
          }
          zone {
            letterOrNumber
            name
          }
          module {
            letterOrNumber
            name
          }
          dutyFreeDirection
          foodCourtDirection
          foodCourtName
          hasSignName
          signName
        }
        media {
          mainImage {
            asset {
              id
              contentType
              description
              fileName
              height
              title
              url
              width
            }
            metadata {
              lastEdited
              editor {
                firstName
                lastName
              }
            }
          }
          images {
            asset {
              id
              contentType
              description
              fileName
              height
              title
              url
              width
            }
            metadata {
              lastEdited
              editor {
                firstName
                lastName
              }
            }
          }
        }
        meta {
          editor {
            firstName
            lastName
            organisation
          }
          lastEdited
        }
        products {
          accessType
          category
          costs {
            cost
            costCurrency
            defaultTaxPercentage
            programme
            projectedCost
            reservationCost
            type
          }
          id
          name
          ppStripeID
          salePrices {
            programme
            salePrice
            salePriceCurrency
            stripePriceID
          }
          salesforceID
          stage
          status
          tier
        }
        sectionsMeta {
          accessTimes {
            editor {
              firstName
              lastName
              organisation
            }
            lastEdited
            edited
          }
          importantInfo {
            editor {
              firstName
              lastName
              organisation
            }
            lastEdited
            edited
          }
          conditions {
            editor {
              firstName
              lastName
              organisation
            }
            lastEdited
            edited
          }
          facilities {
            editor {
              firstName
              lastName
              organisation
            }
            lastEdited
            edited
          }
          location {
            editor {
              firstName
              lastName
              organisation
            }
            lastEdited
            edited
          }
          media {
            editor {
              firstName
              lastName
              organisation
            }
            lastEdited
            edited
          }
        }
        editedSections
        versionStatus
      }
      salesforceID
      category
      code
      legacyCode
      category
      passengerTypes
      partnerBrand {
        id
        name
        partnerIntegrationData {
          partnerIntegrationID
        }
      }
      importantInformation {
        description
      }
      facilities {
        id
        name
        isAvailable
      }
      location {
        timezone
        city
        code
        country
        description
        isoCountryCode
        landside
        latitude
        longitude
        name
        terminal
        type
        meta {
          editor {
            lastName
            firstName
            organisation
          }
          lastEdited
        }
      }
      locationDetails {
        outletZone
        passportControl
        gateNumber
        gateLocationDirection
        concourse {
          letterOrNumber
          name
          directions {
            takeStairs
            takeLift
            takeEscalator
            takeBus
            takeShuttle
            takeTrain
          }
        }
        floor {
          letterOrNumber
          name
          directions {
            takeStairs
            takeLift
            takeEscalator
            takeBus
            takeShuttle
            takeTrain
          }
        }
        level {
          letterOrNumber
          name
          directions {
            takeStairs
            takeLift
            takeEscalator
            takeBus
            takeShuttle
            takeTrain
          }
        }
        pier {
          letterOrNumber
          name
        }
        satellite {
          letterOrNumber
          name
        }
        zone {
          letterOrNumber
          name
        }
        module {
          letterOrNumber
          name
        }
        dutyFreeDirection
        foodCourtDirection
        foodCourtName
        hasSignName
        signName
      }
      meta {
        editor {
          lastName
          firstName
          organisation
        }
        lastEdited
      }
      ancillaryProducts {
        tier
        costs {
          cost
          costCurrency
          programme
          defaultTaxPercentage
          projectedCost
          reservationCost
          type
        }
        salePrices {
          programme
          salePrice
          salePriceCurrency
          stripePriceID
        }
        name
        id
        salesforceID
        status
      }
      products {
        id
        name
        tier
        costs {
          cost
          costCurrency
          programme
          defaultTaxPercentage
          projectedCost
          reservationCost
          type
        }
        partnerIntegrationData {
          partnerProductID
        }
        salePrices {
          programme
          salePrice
          salePriceCurrency
          stripePriceID
        }
        status
        category
        accessType
        salesforceID
        stage
      }
      editedSections
      tier
      conditions {
        allowedSmokingPolicy
        childrenAdmittedFreeNumber {
          maximum
          per
        }
        childrenAdmittedFreeAge {
          from
          to
        }
        childrenAllowed
        childrenMustBeAccompaniedUnderAge
        description
        dressCodeOptions {
          noAboveKneeSkirtsOrDresses
          noBaseballCaps
          noSandals
          noShorts
          noSleevelessTops
          noSportswear
          noTShirts
          smartCasual
        }
        hasDressCode
        minimumChildAge
        smokingAllowed
      }
      display {
        conditions
        locationDetails
      }
      accessTimes {
        accessHours {
          monday {
            startTime
            endTime
          }
          tuesday {
            startTime
            endTime
          }
          wednesday {
            startTime
            endTime
          }
          thursday {
            startTime
            endTime
          }
          friday {
            startTime
            endTime
          }
          saturday {
            startTime
            endTime
          }
          sunday {
            startTime
            endTime
          }
        }
        peakHours {
          startTime
          endTime
        }
        description
        exceptions {
          accessHours {
            startTime
            endTime
          }
          startDate
          endDate
          date
          exceptionType
          isOpen
          name
          priority
          isRecurring
        }
      }
      media {
        mainImage {
          asset {
            id
            contentType
            description
            fileName
            height
            title
            url
            width
          }
          metadata {
            lastEdited
            editor {
              firstName
              lastName
            }
          }
        }
        images {
          asset {
            id
            contentType
            description
            fileName
            height
            title
            url
            width
          }
          metadata {
            lastEdited
            editor {
              firstName
              lastName
            }
          }
        }
      }
      status
      salesforceID
      reservationEmail
      partnerBrand {
        name
      }
      sectionsMeta {
        location {
          editor {
            firstName
            lastName
            organisation
          }
          lastEdited
          edited
        }
        media {
          editor {
            firstName
            lastName
            organisation
          }
          lastEdited
          edited
        }
        accessTimes {
          editor {
            firstName
            lastName
            organisation
          }
          lastEdited
          edited
        }
        conditions {
          editor {
            firstName
            lastName
            organisation
          }
          lastEdited
          edited
        }
        importantInfo {
          editor {
            firstName
            lastName
            organisation
          }
          lastEdited
          edited
        }
        facilities {
          editor {
            firstName
            lastName
            organisation
          }
          lastEdited
          edited
        }
      }
    }
  }
`;
var getOutlet_default = getOutlet;

// src/queries/getEntitlementsForProduct.ts
var getEntitlementsForProduct = import_client2.gql`
  query GetEntitlementsForProduct(
    $productId: ID!
    $bookingEnd: Date
    $bookingStart: Date
    $membershipID: ID
    $retry: Boolean
  ) {
    getEntitlementsForProduct(
      productId: $productId
      bookingEnd: $bookingEnd
      bookingStart: $bookingStart
      membershipID: $membershipID
      retry: $retry
    ) {
      expiryDate
      expired
      id
      redeemed
    }
  }
`;
var getEntitlementsForProduct_default = getEntitlementsForProduct;
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  forecastHealthCheck,
  getAvailableSlots,
  getBookingByID,
  getBookings,
  getConsumer,
  getConsumerByID,
  getEntitlementsForProduct,
  getFlightDetails,
  getOutlet,
  getOutlets,
  getOutletsCount,
  getPartnerBrands,
  getPartnerBrandsCount,
  getPartnerByID,
  getSearchExperiences
});
